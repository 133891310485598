<template>
    <seccion-datos :titulo="contabilidad" tipo="success" @cambios="guardar(id)">
        <div class="row">
                        <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ $t('general.codigoempresa') }}</label>
                        <input type="text" v-model="codigo_empresa" class="form-control nombre_completo dato" id="nombre_completo">
                    </div>
                </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.prefijofacturas') }}</label>
                    <input type="text" v-model="prefijo_factura_empresa" class="form-control nombre_completo dato" id="nombre_completo">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.prefijoabonos') }}</label>
                    <input type="text" v-model="prefijo_abono_empresa" class="form-control nombre_completo dato" id="nombre_completo">
                </div>
            </div>
        </div>
        <div class="row">

        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.subcuentagastogeneral') }}</label>
                    <input type="text" v-model="subcuenta_compras" class="form-control nombre_completo dato" id="nombre_completo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.subcuentaingresosgeneral') }}</label>
                    <input type="text" v-model="subcuenta_ingresos_general" class="form-control nombre_completo dato" id="nombre_completo">
                </div>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id'],
    data() {
        return {
            empresa: '',
            prefijo_factura_empresa: '',
            prefijo_abono_empresa: '',
            ruta_certificado: '',
            clave_certificado: '',
            subcuenta_compras: '',
            subcuenta_ingresos_general: '',
            codigo_empresa: '',
            seleccionado: '',
            contabilidad: '',
        }
    },
    methods: {

        async obtenerEmpresa(id) {
            const api = new PwgsApi;
            const datos = await api.get('empresas/' + id);
            this.empresa = datos.datos;
            this.$store.dispatch('cambiarCargando', true);
            this.prefijo_factura_empresa = this.empresa.prefijo_factura_empresa;
            this.prefijo_abono_empresa = this.empresa.prefijo_abono_empresa;
            this.ruta_certificado = this.empresa.ruta_certificado;
            this.clave_certificado = this.empresa.clave_certificado;
            this.subcuenta_compras = this.empresa.subcuenta_compras;
            this.subcuenta_ingresos_general = this.empresa.subcuenta_ingresos_general;
            this.codigo_empresa = this.empresa.codigo_empresa;
            this.$store.dispatch('cambiarCargando', false);
            this.contabilidad = this.$t('general.contabilidad');
        },
        async guardar(id) {
            /* put  modulos/pwgsapi/index.php/lineas-facturacion/:id   */
            const subidadatos = { prefijo_factura_empresa: this.prefijo_factura_empresa, prefijo_abono_empresa: this.prefijo_abono_empresa, ruta_certificado: this.ruta_certificado, subcuenta_compras: this.subcuenta_compras, subcuenta_ingresos_general: this.subcuenta_ingresos_general, clave_certificado: this.clave_certificado, codigo_empresa: this.codigo_empresa };
            const api = new PwgsApi;
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('empresas/' + this.id, subidadatos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.obtenerEmpresa(id);
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/lineas-facturacion/  */
                try {
                    let res;
                    res = await api.post('empresas/', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/empresas/' + res.id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
    },
    mounted() {

        this.obtenerEmpresa(this.id);
    },
    watch: {
        id() {
            this.obtenerEmpresa(this.id);
        }
    }
}
</script>